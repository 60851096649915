import { KeyLabel, MOD } from './KeyLabel';
export const KeyLabelLithuanian_qwerty: KeyLabel[] = [
  {
    code: 4,
    label: 'A',
  },
  {
    code: 5,
    label: 'B',
  },
  {
    code: 6,
    label: 'C',
  },
  {
    code: 7,
    label: 'D',
  },
  {
    code: 8,
    label: 'E',
    meta: [
      {
        label: '€',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
    ],
  },
  {
    code: 9,
    label: 'F',
  },
  {
    code: 10,
    label: 'G',
  },
  {
    code: 11,
    label: 'H',
  },
  {
    code: 12,
    label: 'I',
  },
  {
    code: 13,
    label: 'J',
  },
  {
    code: 14,
    label: 'K',
  },
  {
    code: 15,
    label: 'L',
  },
  {
    code: 16,
    label: 'M',
  },
  {
    code: 17,
    label: 'N',
  },
  {
    code: 18,
    label: 'O',
  },
  {
    code: 19,
    label: 'P',
  },
  {
    code: 20,
    label: 'Q',
  },
  {
    code: 21,
    label: 'R',
  },
  {
    code: 22,
    label: 'S',
  },
  {
    code: 23,
    label: 'T',
  },
  {
    code: 24,
    label: 'U',
  },
  {
    code: 25,
    label: 'V',
  },
  {
    code: 26,
    label: 'W',
  },
  {
    code: 27,
    label: 'X',
  },
  {
    code: 28,
    label: 'Y',
  },
  {
    code: 29,
    label: 'Z',
  },
  {
    code: 30,
    label: 'Ą',
    meta: [
      {
        label: '1',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '!',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '!',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 31,
    label: 'Č',
    meta: [
      {
        label: '2',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '@',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '@',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 32,
    label: 'Ę',
    meta: [
      {
        label: '3',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '#',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '#',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 33,
    label: 'Ė',
    meta: [
      {
        label: '4',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '$',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '$',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 34,
    label: 'Į',
    meta: [
      {
        label: '5',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '%',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '%',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 35,
    label: 'Š',
    meta: [
      {
        label: '6',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '^',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '^',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 36,
    label: 'Ų',
    meta: [
      {
        label: '7',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '&',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '&',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 37,
    label: 'Ū',
    meta: [
      {
        label: '8',
        modifiers: MOD.RIGHT | MOD.ALT,
      },
      {
        label: '*',
        modifiers: MOD.SFT | MOD.RIGHT | MOD.ALT,
      },
      {
        label: '*',
        modifiers: MOD.RIGHT | MOD.SFT | MOD.ALT,
      },
    ],
  },
  {
    code: 38,
    label: '9',
    meta: [
      {
        label: '(',
        modifiers: MOD.SFT,
      },
      {
        label: '(',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 39,
    label: '0',
    meta: [
      {
        label: ')',
        modifiers: MOD.SFT,
      },
      {
        label: ')',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 45,
    label: '-',
    meta: [
      {
        label: '*',
        modifiers: MOD.SFT,
      },
      {
        label: '*',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 46,
    label: 'Ž',
  },
  {
    code: 47,
    label: '[',
    meta: [
      {
        label: '{',
        modifiers: MOD.SFT,
      },
      {
        label: '{',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 48,
    label: ']',
    meta: [
      {
        label: '}',
        modifiers: MOD.SFT,
      },
      {
        label: '}',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 50,
    label: '(backslash)',
    meta: [
      {
        label: '|',
        modifiers: MOD.SFT,
      },
      {
        label: '|',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 51,
    label: ';',
    meta: [
      {
        label: ';',
        modifiers: MOD.SFT,
      },
      {
        label: ';',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 52,
    label: "'",
    meta: [
      {
        label: '"',
        modifiers: MOD.SFT,
      },
      {
        label: '"',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 53,
    label: '`',
    meta: [
      {
        label: '~',
        modifiers: MOD.SFT,
      },
      {
        label: '~',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 54,
    label: ',',
    meta: [
      {
        label: '<',
        modifiers: MOD.SFT,
      },
      {
        label: '<',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 55,
    label: '.',
    meta: [
      {
        label: '>',
        modifiers: MOD.SFT,
      },
      {
        label: '>',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 56,
    label: '/',
    meta: [
      {
        label: '?',
        modifiers: MOD.SFT,
      },
      {
        label: '?',
        modifiers: MOD.RIGHT | MOD.SFT,
      },
    ],
  },
  {
    code: 550,
    label: '(',
  },
  {
    code: 551,
    label: ')',
  },
  {
    code: 557,
    label: '*',
  },
  {
    code: 559,
    label: '{',
  },
  {
    code: 560,
    label: '}',
  },
  {
    code: 562,
    label: '|',
  },
  {
    code: 563,
    label: ';',
  },
  {
    code: 564,
    label: '"',
  },
  {
    code: 565,
    label: '~',
  },
  {
    code: 566,
    label: '<',
  },
  {
    code: 567,
    label: '>',
  },
  {
    code: 568,
    label: '?',
  },
  {
    code: 4646,
    label: '(',
  },
  {
    code: 4647,
    label: ')',
  },
  {
    code: 4653,
    label: '*',
  },
  {
    code: 4655,
    label: '{',
  },
  {
    code: 4656,
    label: '}',
  },
  {
    code: 4658,
    label: '|',
  },
  {
    code: 4659,
    label: ';',
  },
  {
    code: 4660,
    label: '"',
  },
  {
    code: 4661,
    label: '~',
  },
  {
    code: 4662,
    label: '<',
  },
  {
    code: 4663,
    label: '>',
  },
  {
    code: 4664,
    label: '?',
  },
  {
    code: 5120,
    label: '=',
  },
  {
    code: 5128,
    label: '€',
  },
  {
    code: 5150,
    label: '1',
  },
  {
    code: 5151,
    label: '2',
  },
  {
    code: 5152,
    label: '3',
  },
  {
    code: 5153,
    label: '4',
  },
  {
    code: 5154,
    label: '5',
  },
  {
    code: 5155,
    label: '6',
  },
  {
    code: 5156,
    label: '7',
  },
  {
    code: 5157,
    label: '8',
  },
  {
    code: 5632,
    label: '+',
  },
  {
    code: 5632,
    label: '+',
  },
  {
    code: 5662,
    label: '!',
  },
  {
    code: 5662,
    label: '!',
  },
  {
    code: 5663,
    label: '@',
  },
  {
    code: 5663,
    label: '@',
  },
  {
    code: 5664,
    label: '#',
  },
  {
    code: 5664,
    label: '#',
  },
  {
    code: 5665,
    label: '$',
  },
  {
    code: 5665,
    label: '$',
  },
  {
    code: 5666,
    label: '%',
  },
  {
    code: 5666,
    label: '%',
  },
  {
    code: 5667,
    label: '^',
  },
  {
    code: 5667,
    label: '^',
  },
  {
    code: 5668,
    label: '&',
  },
  {
    code: 5668,
    label: '&',
  },
  {
    code: 5669,
    label: '*',
  },
  {
    code: 5669,
    label: '*',
  },
];
